import React, { FC, useEffect } from 'react';

import { Select } from '@.ui';
import { analyticsStoreSelectors } from '@/Store';

import S from './styles.module.scss';

export const AnalyticsPageSelectStation: FC = () => {
    const stations = analyticsStoreSelectors.use.stations();
    const statisticRequest = analyticsStoreSelectors.use.statisticRequest();
    const updatePageFilters = analyticsStoreSelectors.use.updatePageFilters();
    const loadStations = analyticsStoreSelectors.use.loadStations();

    const handleChange = (value: number | undefined) => {
        if (!statisticRequest.filters) {
            return;
        }

        updatePageFilters({ filters: { ...statisticRequest.filters, station_id: value } });
    };

    useEffect(() => {
        loadStations();
    }, [loadStations, statisticRequest?.statistic_object?.id]);

    return (
        <div className={S.root}>
            <Select placeholder={'All stations'} theme={'white'} absolute value={statisticRequest.filters?.station_id} onChange={handleChange}>
                {stations.map((stationItem) => (
                    <Select.Option key={stationItem.id} id={stationItem.id}>
                        {stationItem.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
