import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButtons } from 'src/Components/Partials/Product/CreateProductForm/components/ActionButtons';

import { Api } from '@.api';
import { ProductType } from '@.api/methods';
import { requiredRule, useDictionaries, useForm, ValidateOptions } from '@.hooks';
import { GetPricesRef, PricesForm } from '@/Components/Partials/Prices';
import { CheckboxField } from '@/Components/Partials/Product/CreateProductForm/components/CheckboxField';
import { CheckboxPickerField } from '@/Components/Partials/Product/CreateProductForm/components/CheckboxPickerField';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';
import { ImageField } from '@/Components/Partials/Product/CreateProductForm/components/ImageField';
import { InputField } from '@/Components/Partials/Product/CreateProductForm/components/InputField';
import { LingualInputField } from '@/Components/Partials/Product/CreateProductForm/components/LingualInputField';
import { LingualTextAreaField } from '@/Components/Partials/Product/CreateProductForm/components/LingualTextAreaField';
import { SelectField } from '@/Components/Partials/Product/CreateProductForm/components/SelectField';
import { SwitcherField } from '@/Components/Partials/Product/CreateProductForm/components/SwitcherField';
import { Auth, getLingualErrors, LinkHelper, Tools } from '@/Services';
import { DictionaryList } from '@/Types';

import S from './styles.module.scss';
import { CreateProductFormProps, ProductData } from './types';
import { validateOptions } from './validate';

/**
 * Form for create product as admin or product manager.
 *
 * TODO: in future decompose and refactor again.
 */
export const CreateProductForm: FC<CreateProductFormProps> = ({ id, edit, permissions, initialProductState, isFormForManagerRole = false, withPrices }) => {
    const auth = Auth.Use();

    const isUDIRequired = !isFormForManagerRole;

    const [product, setProduct] = useState<ProductType | undefined>(initialProductState);
    const [dictionaries, setDictionaries] = useDictionaries();
    const [field, form] = useForm<ProductData>();

    const getPricesRef = useRef<GetPricesRef>(null);

    const navigator = useNavigate();

    const handleCreate = () => {
        form.ifValid((data) => {
            const dataPayload = {
                ...data,
                ages_ids: data.ages_ids ? data.ages_ids : [],
                tags_ids: data.tags_ids ? data.tags_ids : [],
            };

            const apiCreateMethod = () => {
                if (isFormForManagerRole) {
                    const prices = getPricesRef?.current?.getPrices();

                    if (!prices) {
                        return;
                    }

                    return Api.productsProposals().create({
                        ...dataPayload,
                        prices,
                    });
                }

                return Api.products().create(dataPayload);
            };

            apiCreateMethod()
                ?.onSuccess((response) => {
                    if (isFormForManagerRole) {
                        navigator(LinkHelper.productProposalsDetail(response.data.id));
                        return;
                    }
                    navigator(LinkHelper.dataProducts);
                })
                .onValidationError((res) => {
                    const nameErrors = getLingualErrors(res.errors, 'name');
                    const descriptionErrors = getLingualErrors(res.errors, 'description');

                    form.set.errors({
                        ...res.errors,
                        name: nameErrors.length ? nameErrors : undefined,
                        description: descriptionErrors.length ? descriptionErrors : undefined,
                    });
                });
        }, validateOptions(isUDIRequired));
    };

    const handleUpdate = () => {
        if (!id) {
            return;
        }

        if (auth?.user?.is_super || isFormForManagerRole) {
            form.ifValid((data) => {
                const dataPayload = {
                    ...data,
                    ages_ids: data.ages_ids ? data.ages_ids : [],
                    tags_ids: data.tags_ids ? data.tags_ids : [],
                };

                const updateApiMethod = () => {
                    if (isFormForManagerRole) {
                        const prices = getPricesRef?.current?.getPrices();

                        if (!prices) {
                            return;
                        }

                        return Api.productsProposals().update(
                            {
                                ...dataPayload,
                                prices,
                            },
                            id
                        );
                    }

                    return Api.products().update(dataPayload, id);
                };

                updateApiMethod()
                    ?.onSuccess((response) => {
                        if (isFormForManagerRole) {
                            navigator(LinkHelper.productProposalsDetail(response.data.id));
                            return;
                        }

                        navigator(LinkHelper.dataProducts);
                    })
                    .onValidationError((res) => {
                        const nameErrors = getLingualErrors(res.errors, 'name');
                        const descriptionErrors = getLingualErrors(res.errors, 'description');
                        form.set.errors({
                            ...res.errors,
                            name: nameErrors.length ? nameErrors : undefined,
                            description: descriptionErrors.length ? descriptionErrors : undefined,
                        });
                    });
            }, validateOptions(isUDIRequired));

            return;
        }

        if (auth?.user?.permissions?.product_descriptions) {
            form.ifValid(
                (data) => {
                    const { description } = data;

                    if (!description) {
                        return;
                    }

                    const lingualData = Object.fromEntries(Object.entries(description).map(([lang, value]) => [`description_${lang}`, value]));

                    Api.products()
                        .description(Number(id), lingualData)
                        .onSuccess(() => navigator(LinkHelper.dataProducts))
                        .onValidationError((res) => {
                            const nameErrors = getLingualErrors(res.errors, 'name');
                            const descriptionErrors = getLingualErrors(res.errors, 'description');

                            form.set.errors({
                                ...res.errors,
                                name: nameErrors.length ? nameErrors : undefined,
                                description: descriptionErrors.length ? descriptionErrors : undefined,
                            });
                        });
                },
                {
                    description: {
                        type: 'lingual',
                        required: requiredRule,
                        max: {
                            value: 1024,
                            message: 'Field must be up to 1024 characters',
                        },
                    },
                } as ValidateOptions<ProductData>
            );
        }
    };

    const handleDeleteProductProposals = () => {
        if (!id) {
            return;
        }

        if (auth?.user?.is_super || isFormForManagerRole) {
            const deleteApiMethod = Api.productsProposals();

            deleteApiMethod.delete(id).onSuccess(() => {
                navigator(LinkHelper.productProposals);
                return;
            });
        }
    };

    useEffect(() => {
        setProduct(initialProductState);
    }, [initialProductState]);

    useEffect(() => {
        if (edit && product) {
            const { udi, name, description, company_description, brand, sexes, ages, characters, ingredients, occasions, tags, type, strength, images } =
                product;

            const getDescription = () => {
                if (auth?.user?.is_super) return description;

                if (!company_description) return description;

                return { ...Tools.object.filterNullable(description ?? {}), ...Tools.object.filterNullable(company_description) };
            };

            const pickId = (collection: Array<{ id: number }>): Array<number> => {
                return collection.map(({ id }) => id);
            };

            form.set.values({
                udi,
                name,
                description: getDescription(),
                brand_id: brand.id,
                product_type_id: type.id,
                strength_id: strength.id,
                sexes_ids: pickId(sexes),
                ages_ids: pickId(ages),
                tags_ids: pickId(tags),
                images_ids: pickId(images),
                occasions_ids: pickId(occasions),
                characters_ids: pickId(characters),
                ingredients_ids: pickId(ingredients),
            });
        }
    }, [product, edit, auth?.user?.is_super]);

    useEffect(() => {
        const dictionaries: DictionaryList = [
            'StoreGroups',
            'Brands',
            'ProductTypes',
            'Ages',
            'Sexes',
            'Strengths',
            'Occasions',
            'Ingredients',
            'Characters',
            'Tags',
        ];

        setDictionaries(dictionaries);
    }, [setDictionaries]);

    const ActionButtonsComponent = () => (
        <ActionButtons
            edit={edit}
            handleUpdate={handleUpdate}
            handleCreate={handleCreate}
            handleDelete={edit && isFormForManagerRole ? handleDeleteProductProposals : undefined}
        />
    );
    return (
        <div className={S.wrapper}>
            <div className={S.header}>
                <h1>{edit ? 'Edit product' : 'Adding product'}</h1>
                <ActionButtonsComponent />
            </div>

            <div className={S.form}>
                {dictionaries?.brands && (
                    <SelectField
                        {...field('brand_id').register<number>()}
                        headerText={'Product Brand'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries?.brands}
                        placeholder="Search a product Brand"
                        required
                    />
                )}

                <LingualInputField {...field('name').register()} headerText={'Product Name'} isAllowed={!!permissions?.create} required />

                {dictionaries?.product_types && (
                    <SelectField
                        {...field('product_type_id').register<number>()}
                        headerText={'Product type'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries?.product_types}
                        placeholder="Search a product Type"
                        required
                    />
                )}

                {isUDIRequired && (
                    <InputField {...field('udi').register<string>()} headerText={'UDI'} isAllowed={!!permissions?.admin} required regex={/^[0-9]+$/} />
                )}

                {dictionaries?.ages && (
                    <SelectField
                        {...field('ages_ids').register<number[]>()}
                        headerText={'Age'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries?.ages}
                        multiple
                    />
                )}

                {dictionaries?.sexes && (
                    <CheckboxPickerField
                        {...field('sexes_ids').register<(number | string)[]>()}
                        headerText={'Gender'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries?.sexes}
                        required
                    />
                )}

                {dictionaries?.strengths && (edit ? form.state.strength_id?.value : true) && (
                    <SwitcherField
                        {...field('strength_id').register<number | string>()}
                        initialValue={form.state.strength_id?.value}
                        headerText={'Strength'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries?.strengths}
                        required
                    />
                )}

                {dictionaries?.occasions && (
                    <CheckboxPickerField
                        {...field('occasions_ids').register<(number | string)[]>()}
                        headerText={'Occasion'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries?.occasions}
                        required
                    />
                )}

                {dictionaries?.ingredients && (
                    <CheckboxField
                        {...field('ingredients_ids').register<number[]>()}
                        headerText={'Ingredients'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries.ingredients}
                        activeValues={form.state.ingredients_ids?.value}
                        fieldName={'ingredients_ids'}
                        onSet={(name, values) => form.set.fieldValue(name, values)}
                        required
                    />
                )}

                {dictionaries?.characters && (
                    <CheckboxField
                        {...field('characters_ids').register<number[]>()}
                        headerText={'Character'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries.characters}
                        activeValues={form.state.characters_ids?.value}
                        fieldName={'characters_ids'}
                        onSet={(name, values) => form.set.fieldValue(name, values)}
                        required
                    />
                )}

                {dictionaries?.tags && (
                    <CheckboxField
                        {...field('tags_ids').register<number[]>()}
                        headerText={'Tags'}
                        isAllowed={!!permissions?.create}
                        dictionariesValues={dictionaries.tags}
                        activeValues={form.state.tags_ids?.value}
                        fieldName={'tags_ids'}
                        onSet={(name, values) => form.set.fieldValue(name, values)}
                    />
                )}

                <LingualTextAreaField {...field('description').register()} headerText={'Description'} isAllowed={true} />

                <ImageField {...field('images_ids').register<number[]>()} headerText={'Image'} isAllowed={!!permissions?.create} value={product?.images} />

                {withPrices && (
                    <FormItemWrapper>
                        <PricesForm title={'Volume | Price'} ref={getPricesRef} initialValue={initialProductState?.prices} />
                    </FormItemWrapper>
                )}
            </div>

            <div className={S.footer}>
                <ActionButtonsComponent />
            </div>
        </div>
    );
};
