import { requiredRule, ValidateOptions } from '@/Components/Hooks';
import { PriceData } from '@/Types/Prices';

export const validateOptions: ValidateOptions<PriceData> = {
    currency_id: {
        type: 'number',
        required: {
            value: true,
            message: 'Field is required',
        },
    },
    price: {
        type: 'number',
        required: requiredRule,
    },
    volume_fl_oz: {
        type: 'number',
        required: requiredRule,
    },
    volume_ml: {
        type: 'number',
        required: requiredRule,
    },
    store_id: {
        type: 'number',
        required: requiredRule,
    },
};
