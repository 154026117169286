import useModal from '@fipnooone/react-modal';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Api, Code } from '@.api';
import { useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { Button } from '@.ui';
import { ModalList } from '@/Components/Modal/Dialogs';
import { Buttons } from '@/Components/Partials';
import { Price } from '@/Components/Partials/Prices';
import { Controls, PriceValue } from '@/Components/Partials/Prices/Price/types';
import { isObject } from '@/Lib';
import { Auth, LinkHelper } from '@/Services';
import { PricesResponse, ProductPreview } from '@/Services/api/methods';
import { DictionaryResponseKnown } from '@/Types/api/Dictionaries';
import { PriceData } from '@/Types/Prices';

import { validateOptions } from './Price/validate';
import { PricesData } from './types';

export const ProductPrices: React.FC = () => {
    const auth = Auth.Use();
    const [product, setProduct] = useState<ProductPreview>();
    const [availableStores, setAvailableStores] = useState<DictionaryResponseKnown['stores']>([]);
    const [availableCurrencies, setAvailableCurrencies] = useState<Record<number, DictionaryResponseKnown['currencies']>>({});
    const [isLoading, setLoading] = useState(false);
    const [field, form] = useForm<PricesData>();
    const [cModal, modal] = useModal();
    const controls = useRef<Record<string, Controls>>({});

    const updatePrices = (res: PricesResponse) => {
        form.reset.form();

        if (res.prices.length) {
            form.set.values(
                Object.fromEntries(
                    res.prices.map((price) => {
                        const uuid = uuidv4();

                        return [uuid, { data: price, uuid }];
                    })
                )
            );

            setProduct(res.product);

            return;
        }

        const uuid = uuidv4();

        field(uuid).value({
            uuid,
            data: {},
        });
    };

    const get = (id: number) =>
        Api.products()
            .getPrices(id)
            .onSuccess((res) => updatePrices(res.data));

    const id = useId(get);

    useEffect(() => {
        const companyId = auth?.user?.company?.id;

        const getCurrencies = async () => {
            setLoading(true);

            const response = await Api.dictionaries().byNames(['Stores'], {
                ...(companyId
                    ? {
                          stores: {
                              company_id: companyId,
                          },
                      }
                    : {}),
            });

            if (response.code !== Code.Success) {
                return;
            }

            const stores = response.data.stores || [];

            setAvailableStores(stores);

            const storeRequests = stores.map(async (store) => {
                const response = await Api.dictionaries().byNames(['Currencies'], {
                    currencies: {
                        store_id: store.id,
                    },
                });

                if (response.code !== Code.Success) {
                    return [];
                }

                return response.data.currencies || [];
            });

            const storeResponses = await Promise.all(storeRequests);

            setAvailableCurrencies(
                storeResponses.reduce(
                    (acc, currencies, index) => ({ ...acc, [stores[index].id]: currencies }),
                    {} as Record<number, DictionaryResponseKnown['currencies']>
                )
            );

            setLoading(false);
        };

        getCurrencies();
    }, [auth?.user?.company?.id]);

    const findDuplicates = () => {
        const list: PriceData[] = [];

        const values = Object.values(form.state).filter((v) => v !== undefined && typeof v === 'object' && v.value !== undefined);

        const seen: Record<
            string,
            PriceData & {
                errors: (errors: Record<string, string> | string) => void;
                index: number;
            }
        > = {};
        let errored = false;

        values.forEach((item, index) => {
            if (typeof item === 'string' || !item?.value) {
                return;
            }

            const itemControls = controls.current[item.value.uuid];

            if (!itemControls) {
                return;
            }

            const { ifValid, clearErrors, errors } = itemControls;

            try {
                clearErrors();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }

            ifValid((data) => {
                const duplicateItem = {
                    ...data,
                    volume_fl_oz: data.volume_fl_oz && Math.ceil(data.volume_fl_oz * 10) / 10,
                    volume_ml: data.volume_ml && Math.ceil(data.volume_ml),
                };

                const dubs = Object.values(seen).filter((value) => {
                    const { currency_id, price, volume_fl_oz, volume_ml, store_id } = value;

                    return (
                        currency_id === data.currency_id &&
                        price === data.price &&
                        volume_fl_oz === data.volume_fl_oz &&
                        volume_ml === data.volume_ml &&
                        store_id === data.store_id
                    );
                });

                if (dubs.length !== 0) {
                    dubs.push({ ...duplicateItem, errors, index: index + 1 });
                }

                dubs.forEach((value) => {
                    value.errors(
                        `Duplicate of ${dubs
                            .filter((val) => value.index !== val.index)
                            .map((value) => `#${value.index}`)
                            .join(', ')}`
                    );
                });

                if (dubs.length !== 0) {
                    errored = true;
                }

                seen[(item.value as PriceValue)?.uuid] = { ...duplicateItem, errors, index: index + 1 };

                list.push(duplicateItem);
            }, validateOptions);
        });

        if (list.length !== values.length || errored) return;

        return list;
    };

    const handleSave = () => {
        if (!id) {
            return;
        }

        const list = findDuplicates();

        if (!list) {
            return;
        }

        Api.products()
            .postPrices({ prices: list }, id)
            .onSuccess(({ data, message }) => {
                modal.set(<ModalList message={message} />);

                updatePrices(data);
            });
    };

    const handleDelete = (uuid: string) => {
        field(uuid).value(undefined);
    };

    const handleAdd = () => {
        const uuid = uuidv4();

        field(uuid).value({
            uuid,
            data: {},
        });
    };

    const handleControls = (uuid: string, newControls: Controls) => (controls.current[uuid] = newControls);

    const prices = Object.values(form.state).filter((priceItem) => isObject(priceItem) && Boolean(priceItem?.value)) as {
        value: PriceValue;
    }[];

    return (
        <>
            {cModal}
            <Layout
                name="data"
                breadcrumbs={[
                    { name: 'Products', link: LinkHelper.dataProducts },
                    { name: 'Prices', current: true },
                ]}
            >
                <h1>
                    {`Product prices ${product ? `for ${product.name} ${product.udi}` : ''}`}
                    <Button color="brown" text="Add price" onClick={handleAdd} />
                </h1>
                <div className="action-form">
                    {!isLoading &&
                        prices.map((item) => {
                            const storeId = item.value.data.store_id;

                            return (
                                <Price
                                    uuid={item.value.uuid}
                                    key={`Price-${item.value.uuid}-${item.value.data.store_id}`}
                                    currencies={storeId ? availableCurrencies[storeId] || [] : []}
                                    stores={availableStores || []}
                                    onAdd={handleAdd}
                                    onDelete={handleDelete}
                                    priceActionVariant={'delete'}
                                    controls={handleControls}
                                    {...field(item.value.uuid).register<PriceValue>()}
                                />
                            );
                        })}
                    <Buttons text={'Save'} onClickSave={handleSave} />
                </div>
            </Layout>
        </>
    );
};
